<template>
    <v-container class="pa-0">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <v-form
                ref="subscriptionFormA"
                @submit.prevent="handleSubmit(handleSubmission)"
            >
                <v-container class="py-0">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="firstname"
                                rules="required"
                                class="validationWrapper"
                            >
                                <div>
                                    <span class="validationMessage">
                                        {{ errors[0] }}
                                    </span>
                                    <v-text-field
                                        id="firstname"
                                        v-model="firstname"
                                        name="firstname"
                                        :placeholder="localize('firstName')"
                                        outlined
                                        flat
                                        dense
                                        :hide-details="true"
                                    ></v-text-field>
                                </div> </ValidationProvider
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="lastname"
                                rules="required"
                                class="validationWrapper"
                            >
                                <div>
                                    <span class="validationMessage">
                                        {{ errors[0] }}
                                    </span>
                                    <v-text-field
                                        id="lastname"
                                        v-model="lastname"
                                        name="lastname"
                                        :placeholder="localize('lastName')"
                                        outlined
                                        flat
                                        dense
                                        :hide-details="true"
                                    ></v-text-field>
                                </div> </ValidationProvider
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="jobFunction"
                                rules="required"
                                class="validationWrapper"
                            >
                                <div>
                                    <span class="validationMessage">
                                        {{ errors[0] }}
                                    </span>

                                    <v-select
                                        id="jobFunction"
                                        v-model="job_function"
                                        :items="jobRoleOptions"
                                        item-value="value"
                                        item-text="label"
                                        name="jobFunction"
                                        :placeholder="localize('jobFunction')"
                                        outlined
                                        flat
                                        dense
                                        :hide-details="true"
                                    ></v-select>
                                </div> </ValidationProvider
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="company"
                                rules="required"
                                class="validationWrapper"
                            >
                                <div>
                                    <span class="validationMessage">
                                        {{ errors[0] }}
                                    </span>
                                    <v-text-field
                                        id="company"
                                        v-model="company"
                                        name="company"
                                        :placeholder="localize('company')"
                                        outlined
                                        flat
                                        dense
                                        :hide-details="true"
                                    ></v-text-field>
                                </div> </ValidationProvider
                        ></v-col>
                        <v-col cols="12" class="text-center d-flex flex-column">
                            <div>
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    :loading="loader"
                                    :disabled="skipLoader"
                                    ><TranslatableStringComponent
                                        :tstring="{ string: 'submit' }"
                                /></v-btn>
                            </div>
                            <div>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="skipLoader"
                                    :disabled="loader"
                                    @click="handleSkip()"
                                    ><TranslatableStringComponent
                                        :tstring="{ string: 'skip' }" /><fa
                                        :icon="['fal', 'chevron-right']"
                                        class="fa-md ml-2"
                                /></v-btn>
                                <p v-if="isError" class="red--text">
                                    *
                                    <TranslatableStringComponent
                                        :tstring="{
                                            string:
                                                'somethingWentWrongPleaseTryAgainLater',
                                        }"
                                    />.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import handleRecaptcha from '~/mixins/handleRecaptcha'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'

export default {
    name: 'FormSubscriptionPartB',
    components: {
        ValidationProvider,
        ValidationObserver,
        TranslatableStringComponent,
    },
    mixins: [handleRecaptcha],
    data() {
        return {
            loader: false,
            skipLoader: false,
            isError: false,
            firstname: '',
            lastname: '',
            job_function: '',
            company: '',
        }
    },
    computed: {
        ...mapState('subscription', [
            'subscribedId',
            'subscribedEmail',
            'subscribedCreatedAt',
        ]),
        ...mapState('formSelectOptions', ['jobRoleOptions']),
    },
    methods: {
        async handleSubmission() {
            try {
                this.isError = false
                this.loader = true
                const formPayload = {
                    email: this.subscribedEmail,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    persona: this.job_function,
                    company: this.company,
                    isSubscribedToNewsletter: true,
                }
                await this.$axios.post('dynamodb-update-subscription', {
                    id: this.subscribedId,
                    createdAt: this.subscribedCreatedAt,
                    ...formPayload,
                })
                await this.$axios.post(
                    'submitted-web-form',
                    {
                        'form-name': 'subscribeForm',
                        ...formPayload,
                    },
                    {
                        params: {
                            obj: 'y',
                        },
                    }
                )
                this.loader = false
                this.$store.commit('subscription/saveFormAResponse', {
                    subscribedId: '',
                    subscribedEmail: '',
                    subscribedCreatedAt: null,
                })
                this.resetForm('subscriptionFormA')
                this.$store.commit('subscription/handleSubscriptionTab')
            } catch (error) {
                this.loader = false
                this.isError = true
                console.log(error)
            }
        },
        async handleSkip() {
            try {
                this.isError = false
                this.skipLoader = true
                await this.$axios.post(
                    'submitted-web-form',
                    {
                        'form-name': 'subscribeForm',
                        email: this.subscribedEmail,
                        'customData.isSubscribedToNewsletter': true,
                    },
                    {
                        params: {
                            obj: 'y',
                        },
                    }
                )
                this.skipLoader = false
                this.resetForm('subscriptionFormA')
                this.$store.commit('subscription/handleSubscriptionTab')
            } catch (error) {
                this.skipLoader = false
                this.isError = true
                console.log(error)
            }
        },
    },
}
</script>
